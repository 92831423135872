.imagePreview {
    display: block;
    width: 80%;
    max-width: 600px !important;
    max-height: 180px;
    height: auto;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    border-radius: 3px;
    border: 1px;
}

.imagePreviewContainer {
    position: relative;
}

.loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingGif {
    width: 50px; 
    height: 50px;
}

.ocrWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; 
}

.ocrBox {
    resize: none;
    background-color: rgba(36, 36, 36, 0.127);
 /*   -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
     box-shadow: 0 -15px 15px rgba(0, 0, 0, 0.5), 15px 0 15px rgba(0, 0, 0, 0.5), -15px 0 15px rgba(0, 0, 0, 0.5);
 */    border-color: rgba(12, 12, 12, 0.566);
    padding: 20px; 
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.caricaButton {
    background-color: transparent; 
    border: 2px dashed rgba(255, 255, 255, 0.5); 
    display: flex; 
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    color: var(--bb-white);
    font-size: 1rem; 
    cursor: pointer; 
    padding: 10px; 
    transition: background-color 0.3s ease;
    border-radius: 3px;
}

.caricaButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
}


/*  effect on loading */



/* Container for the image preview */
.imagePreviewContainer {
    position: relative;
    display: inline-block;
  }
  
  /* Wrapper around the image to apply the loading effect */
  .imageWrapper {
    position: relative;
    overflow: hidden;
  }
  
  /* The image itself */
  .imagePreview {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  /* Loading effect (scrolling gradient overlay) */
  .loadingEffect::before {
    content: '';
    position: absolute;
    top: 0;
    left: -150%; /* Start the gradient off-screen */
    width: 300%; /* Ensures smooth scrolling across the image */
    height: 100%;
    background: linear-gradient(
        90deg, 
        rgba(255, 255, 255, 0.1) 0%,  /* Transparent start */
        rgba(255, 255, 255, 0.9) 50%, /* Bright white center */
        rgba(255, 255, 255, 0.1) 100% /* Transparent end */
    );
    animation: shimmer 1.5s infinite; /* Faster animation for clearer effect */
    z-index: 1; /* Ensure it overlays the image properly */
    pointer-events: none; /* Prevent it from blocking interactions with the image */
    border-radius: 8px; /* Optional: rounded corners for a polished look */
    mix-blend-mode: lighten; /* Ensure it blends well with the image underneath */
}

@keyframes shimmer {
    0% {
        left: -150%;
    }
    100% {
        left: 150%; /* Moves the gradient completely across the image */
    }
}