.sliderCard {
    padding: 1rem 0rem;
}

.sliderCard_box {
    transition: all .3s ease-in;
    border-radius: 2rem;
    padding: 1.2rem;
    background-color: rgba(58, 58, 58, 0.1); /* Semi-transparent white background */
    backdrop-filter: blur(10px); /* Apply blur effect */
    border: 1px solid rgba(58, 58, 58, 0.1); /* Transparent border */
    box-shadow: 0 0 20px rgba(58, 58, 58, 0.1); /* Shadow effect */
    min-height: 60vh;
    min-width: 300px;
}

.sliderCard_box:hover {
    /*box-shadow*/
    box-shadow: 0 0 20px rgba(187, 10, 11, 0.1); /* Shadow effect */
}

.sliderCard_author {
    position: absolute;
    bottom: 2vh;
    right: 0;
    padding: 2rem;
}


@media screen and (min-device-width: 320px) and (max-device-width: 375px){
    .sliderCard {
        padding: .5rem;
    }
}