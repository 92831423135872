.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .tabList {
    display: flex;
    margin-bottom: 20px;
  }
  
  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease;
    border-radius: 40px;
  }
  
  .activeTab {
    color: var(--bb-white);
    border: 1px solid var(--bb-white);
  }
  
  .inactiveTab {
    color: #b3b3b3;
  }
  
  .translation, .analysis {
    display: flex;
    justify-content: center;
    animation: fadeIn 0.3s ease-in;
    background-color: #0C0C0C;
    border-radius: 4px;
    padding: 1rem 0.5rem;
    padding-bottom: 0;
    margin-bottom: 1.5rem;
  }

  .analysis {
    background-color: #0C0C0C;
    border-radius: 4px;
    padding: 1rem 0.5rem;
    padding-bottom: 0;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }