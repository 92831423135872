.footerContainer {
    margin-top: auto; 
    height: 8.2rem;
    width: 100%;
    z-index: 100;
    padding: 0 3.2rem;
    border-radius: 1.5rem 1.5rem 0 0 ;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
}

.textContainer {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

.text, .link, .textBold {
    font-size: 1.1rem;
    line-height: 120%;
    letter-spacing: 0.15px;
    align-self: center;
}

.text {
    height: 1.1rem;
}

.textBold {
    font-weight: bold !important;
    height: 1.1rem;
}

.link {
    font-size: 1rem;
    margin-right: 1rem;
}

.linkGroup {
    order: 1; 
}

.iconContainer {
    order: 2;
}

.socialIcon > * {
    width: 1rem;
}

@media screen and (max-width: 1124px) {

    .footerContainer {
        flex-direction: column;
        height: 7.5rem;
        padding: 1rem 1.5rem;
        justify-content: space-around;
    }

    .textContainer {
        display: flex;
        flex-direction: column;
        align-content: center;
    }

    .linkContainer {
        flex-direction: column;
        align-content: center;
        margin-top: 0.3rem;
    }

    .text {
        font-weight: bold !important;
    }
    
    .textBold {
        font-weight: normal !important;
    }

    .link {
        margin-right: 0.5rem;
    }

    .iconContainer {
        justify-content: center;
        order: 0;
    }
    
}


/*Mobile size*/
@media screen and (max-width: 768px) {

    .linkContainer {
        margin-top: -0.5rem;
    }

    .text, .link, .textBold {
        font-size: 0.75rem;
        line-height: 120%;
    }
    
}