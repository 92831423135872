.gbutton {
    background-color: rgba(58, 58, 58, 0.8);
    border: solid 0px ;
    border-radius: 15px;
    text-decoration: none;
    box-shadow: rgba(253, 253, 253, 0.3) 0px 0px 38px, rgba(253, 253, 253, 0.22) 0px 0px 12px;
    transition: background-color 0.3s, box-shadow 0.3s; 
}

.gbutton:hover {
    background-color: rgba(58, 58, 58, 1);
    box-shadow: rgba(253, 253, 253, 0.5) 0px 0px 42px, rgba(253, 253, 253, 0.32) 0px 0px 14px; 
}

.icon {
    margin-top: 2px;
    margin-right: 8px;
    margin-bottom: 5px;
}