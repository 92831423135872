.textareaWrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.textareaUser {
  resize: none;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-color: rgba(12, 12, 12, 0.566);
  width: 100%;
  box-sizing: border-box; 
  border-radius: 4px;
}

.textareaUser::-webkit-scrollbar {
  width: 8px; 
}

.textareaUser::-webkit-scrollbar-thumb {
  background-color: var(--bb-gray); /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners */
}

.textareaUser::-webkit-scrollbar-track {
  color: transparent;
  border-radius: 0px;
}

/* Camera Button */
.cameraButton {  
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: transparent; /* Add background to make it stand out */
  box-shadow: 0px 2px 5px rgba(153, 153, 153, 0.35);
}

.cameraButton:hover {
  color: #ffffff;
  background-color: rgba(187, 10, 11, 0.85); /* Change background on hover */
}

.startButton {
  color: var(--bb-white);
  border: none;
  background-color: var(--bb-red); /* Add background to make it stand out */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.countBox {
  color: var(--bb-white);
  padding: 0.4rem;
  border-radius: 10px;
  font-size: small;
  display: flex;
  width: auto;
  justify-content: center;
  font-size: 70%;
}

.loadingOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
}