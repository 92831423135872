.historyWrap {
    padding: 2rem;
}

.title {
    font-weight: 700;
    font-size: 1.5em;
    line-height: 1em;
    letter-spacing: 1.25px;
    margin-bottom: 1.5rem;
}

.title > svg {
    width: 1.25rem !important;
    margin-left: 0.3rem;
}

.subtitle {
    font-weight: 700;
    font-size: 1em;
    line-height: 1.2em;
    letter-spacing: -2%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text {
    font-weight: 400;
    font-size: 0.8em;
    line-height: 0.9em;
    letter-spacing: -2%;
    color: #767676;
}

.list {
    gap: 10px;
}

.listItem {
    background-color: #0C0C0C;
    border-radius: 8px;
    padding: 1rem;
    gap: 8px;
}

.listItem:hover {
    background-color: #101010;
    cursor: pointer;
}