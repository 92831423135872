
.nav_logo_image {
    width: 6vw;
    height: 6vh;
}

.navLink {
    text-decoration: none;
}

.verticalSeparator {
    display: inline-block;
    width: 1px;
    height: 40%; 
    background-color: rgba(255, 255, 255, 0.7);
    margin: 0 10px; 
  }