.profile_box {
    background-color: #1F1F1F;
}

.unlock_button {
    background: #BC0B0B !important;
    border: none !important;
    width: 100% !important;
    letter-spacing: 0.1em !important;
    font-weight: 600 !important;
}

.delete_button {
    width: 100% !important;
    letter-spacing: 0.1em !important;
    font-weight: 600 !important;
    background-color: #8B0000 !important;
    border: none !important;
    transition: all 0.3s ease !important;
}

.delete_button:hover {
    background-color: #A00000 !important;
}

.alert {
    position: relative;
    width: 100% !important;
}

.mail_alert {
    position: fixed;
    bottom: 4rem;
    width: 100% !important;
}