.modalContent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: black;
  box-shadow: none;
  z-index: 80;
  min-width: 380px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 70;
  background-color: var(--bb-black);
}

.uploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.fileInput {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  color: black;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 8px;
  width: 80%;
  background-color: #f9f9f9;
  text-align: center;
}

.button {
  color: white;
  cursor: pointer;
  transition: color ease 0.2s;
}

.button:hover {
  color: #790505;
}

/* UPLOAD COMPONENT */
.container {
  height: 80vh;
  width: auto;
  min-width: 40vw;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, .2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  background-color: rgb(49, 48, 48);
  transition: all 0.3s ease;
}

.header {
  flex: 1;
  width: 100%;
  border: 2px dashed rgba(187, 10, 11, 0.85);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header svg {
  height: 100px;
}

.header p {
  text-align: center;
  color: white;
}

.footer {
  background-color: rgb(73, 73, 73);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
  transition: opacity ease 0.1s;
}

.footer:hover {
  opacity: 0.9;
}

.footer p {
  flex: 1;
  text-align: center;
}

.dragging {
  border-color: #BB0A0B;
  background-color: rgba(187, 10, 11, 0.1);
}

.rotateButton {
  background-color: #5d5d5d;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color ease 0.2s;
  display: flex;             
  align-items: center;       
}

.rotateButton:hover {
  background-color: #4a4a4a;
}

.rotateIcon {
  margin-right: 5px;         
  font-size: 1.2em;         
}

.buttonContainer {
  display: flex;
  gap: 10px; 
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 990px) {
  .cropContainer {
    display: block;
    align-items: center;
    justify-content: center;
    position: relative; 
    width: 100%;
    height: 60vh;
    max-width: 100%;
    max-height: 60vh;
    border-radius: 10px;
    object-fit: cover;
    margin: 0 auto;
  }

  .rotateContainer {
    display: block;
    align-items: center;
    justify-content: center;
    position: relative; 
    width: 100%;
    height: 60vh;
    max-width: 100%;
    max-height: 60vh;
    border-radius: 10px;
    object-fit: cover;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .reactCrop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-height: 60vh;
    border-radius: 10px;
  }

  .img {
    max-height: 60vh;
    border-radius: 10px;
    transition: transform 0.3s ease;
    object-fit: contain;
  }
}

@media screen and (min-width: 991px) {
  .cropContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; 
    width: 100%;
    height: 60vh;
    max-width: 100%;
    max-height: 60vh;
    border-radius: 10px;
    object-fit: cover;
    margin: 0 auto;
  }

  .rotateContainer {
    display: block;
    align-items: center;
    justify-content: center;
    position: relative; 
    width: 100%;
    height: 60vh;
    max-width: 100%;
    max-height: 60vh;
    border-radius: 10px;
    object-fit: cover;
    margin: 0 auto;
  }
  
  .reactCrop {
    display: block;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    max-height: 60vh;
    border-radius: 10px;
  }

  .img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease;
    object-fit: cover;
    transform-origin: center center; 
  }
}