.profile_box {
    background-color: #1F1F1F;
}

.profile_box > * {
    width: 100%;
}

.logout_button:hover {
    color: #FFFFFF;
}

.logout_button {
    color: #D3D4D5 !important;
    font-weight: 400 !important;
    font-family: Arial, sans-serif !important;
}

.profile_info {
    padding-top: 1rem;
    border-bottom: 1px solid #f4f4f481;
}

.profile_info:hover {
    background-color: #1A1A1A !important;
    cursor: pointer !important;
}
.profile_info:active {
    background-color: #1A1A1A !important;
}

.info {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: row;
    color: #545454;
    font-size: 1.5em;
    justify-content: space-between;
    align-items: end;
    padding-bottom: 1rem;
}

.info > div > p {
    color: #FFF;
}

.banner{
    background: linear-gradient(90deg, #7b0000, #a90c0d, #6d0001);
    height: 10rem;
    padding: 1rem;
    position: relative;
}

.banner:hover {
    cursor: pointer;
}

.logo_image{
    height: 30%;
    position: absolute;
    top: 1rem;
    left: auto;
    right: 1rem;
    display: flex;
    justify-content: end;
    opacity: 80%;
}



.divider {
    width: 100%;
    height: 1px;
    background-color: #D3D4D5;
}

.back {
    color: #545454;
    font-size: 1.5em;
}

.back:hover {
    cursor: pointer;
}

.hidden {
    display: none !important;
}