.slider {
    width: 100%;
}

.slider_box {
    width: 100%;
    margin: 0 auto;
    padding: 0 -1rem 8rem 0;
}

.slider_box_button {
    display: flex;
    justify-self: space-between;
    align-items: center;
}

.slider_box_button_btn {
    display: flex;
    align-items: center;
    gap: 2rem;
    font-size: 2rem;
}

.slider_box_button_btn_icon {
    border: 1px solid red;
    padding: 1rem;
    display: flex;
    align-items: center;
    border-radius: 50%;
    transition: all .3s ease-in;
    cursor: pointer;
}

.slider_box_button_btn_icon:hover {
    background-color: black;
    color: red;
}

.slider_box_items {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
}

.slider_box_item {
    display: grid;
    grid-template-columns: repeat(6, 20.4rem);
    gap: 1rem;
    padding: 2rem 0rem;
    cursor: grab;
}

.slider_box_item::-webkit-scrollbar {
    display: none;
}

