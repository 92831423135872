.checkoutPage {
    min-height: 100vh;
}

.checkoutBox {
    border: solid 1px var(--bb-gray);
    border-radius: 20px;
    padding: 3em;
    box-shadow: rgba(153, 153, 153, 0.35) 0px 5px 15px;
}

.navLink {
    text-decoration: none;
}