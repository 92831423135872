.boxTeseo {
  width: 100%;
  min-height: 500px;
}

.normalSizeBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.popup_container {
  top: 50%;
  left: 50%;
  height: fit-content;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  z-index: 1000;
  border: solid 1px var(--bb-gray);
  border-radius: 20px;
  box-shadow: rgba(153, 153, 153, 0.35) 0px 5px 15px;
  position: fixed;
}

.icon_close {
  position: absolute;
  top: 5%;
  right: 10%;
  cursor: pointer;
}

.separator {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
}

.separator_text {
  position: absolute;
  background-color: rgb(31, 31, 31);
  padding: 0 10px;
  top: 20px;
  font-size: 14px;
  color: #ccc;
  z-index: 3;
}

.dots_container {
  display: flex;
  gap: 20px; 
  justify-content: center;
  align-items: center;
  background-color: #1e1e1e; 
  height: 70px;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #3d3c3c;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dot.active {
  width: 30px; 
  height: 30px; 
  font-size: 16px; 
  background-color: #5e5e5e;
}

.dot span {
  display: block;
}