.boxTeseo {
    width: 30vw;
    height: 100%;
}

.pay_icon {
    background-color: rgb(187, 10, 11, 0.6);
    color: var(--bb-white);
    border-radius: 20px;
}

.text_pay_icon {
    line-height: 1.1em;
}

@media screen and (max-width: 576px) {
    .boxTeseo {
        width: 90vw;
    }
}