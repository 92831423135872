.box {
    margin-top: 4rem !important;
}

.top {
    padding-top: 5rem;
}

.message_box {
    position: relative;
}

.logo_image{
    height: 30%;
    position: absolute;
    top: 0.5rem;
    left: auto;
    right: 0.5rem;
    display: flex;
    justify-content: end;
    opacity: 80%;
}

.goback_button {
    background: #BC0B0B !important;
    border: none !important;
    height: 3.3rem;
    letter-spacing: 0.1em !important;
    font-weight: 600 !important;
    padding: 0 2rem !important;
}

.goback_button:hover {
    background: #A90A0A !important;
}

.ypadding, .top {
    padding-bottom: 2.5rem;
}

@media screen and (max-width: 550px) {
    .logo_image{
        height: 15%;
    }
}