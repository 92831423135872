.stickyBanner {
    background: linear-gradient(90deg, #7b0000, #a90c0d, #6d0001);
    width: 80%;
    max-width: 80%;
    padding: 1rem;
    border-radius: 0.5rem;
    position: fixed;
    z-index: 70;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, padding 0.3s ease-in-out;
}

.background-teseo-component {
    width: 100%;
    /* Ensures full width */
    max-width: none;
    /* Removes any limiting max-width */
    margin: 0;
    /* Removes any margins */
    padding: 0;
    /* Optional, only if padding is affecting width */
}

.stickyBannerMotion {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.stickyBanner.noSticky {
    position: static;
    transform: none;
    display: none;
}

.buttonStickyBanner {
    border-radius: 8px;
    background-color: #fff;
    color: var(--bb-black);
}

.lifetime,
.normal {
    margin: 0 !important;
    padding: 1.1rem 1.3rem;
    border-radius: 20px;
    width: fit-content;
}


.lifetime {
    background-image: url('../images/banner/banner-background.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.lifetime>div>.leftSide {
    background-image: url('../images/banner/banner-white-background.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.leftSide {
    border-radius: 24px;
    padding: 4rem 3.5rem !important;
}

.title {
    font-weight: 700 !important;
    font-size: 2em;
    letter-spacing: 4%;
    line-height: 120%;
    margin-bottom: 2rem;
}

.text {
    font-size: 1em;
    letter-spacing: 0.15px;
    line-height: 120%;
    margin-bottom: 1.7rem;
}

.margin,
.comingSoon {
    margin-right: 0.5rem;
    margin-bottom: 1.7rem;
}

.comingSoon {
    font-size: 0.8em;
    line-height: 120%;
    letter-spacing: 0.15px;

    background-color: #D0FAD4;
    border-radius: 4px;
    padding: 5px 10px;
}

.unlock_button {
    height: 40px;
    width: 240px;
    background-color: #0C0C0C !important;
    border: none;
    border-radius: 2.3rem !important;


    font-size: 1em;
    line-height: 1em;
    letter-spacing: 0.4px;

    gap: 0.5rem
}

.rightSide {
    display: flex;
    justify-content: center;
    margin-left: 3rem;
}

.imgDesktop,
.imgMobile {
    height: 100%;
}


@media screen and (min-width: 992.01px) {

    .imgMobile {
        display: none;
    }

}


@media screen and (max-width: 1024px) {

    .leftSide {
        padding: 2.5rem 2.2rem !important;
        flex: 0 0 auto !important;
        width: 58.33333333% !important;

    }

    .title {
        font-size: 1.5rem;
    }

    .comingSoon,
    .title {
        margin-bottom: 1.5rem;
    }

    .text,
    .margin,
    .comingSoon {
        margin-bottom: 1.3rem;
    }

    .margin,
    .comingSoon {
        margin-right: 0.2rem;
    }

    .rightSide {
        padding-left: 2rem !important;
        margin: 0;
        flex: 0 0 auto !important;
        width: 41.66666667% !important;
    }

}


/*mobile e tablet al momento*/
@media screen and (max-width: 992px) {

    .normal {
        /*NOrmal*/
        padding: 0;
    }

    .lifetime {
        padding: 8.6rem 1rem 1rem 1rem;
        background-position: right;
    }

    .rightSide {
        display: none;
    }

    .imgMobile {
        display: block;
        height: auto;
        margin-bottom: 1rem;
    }

    .leftSide {
        background-image: none !important;
        padding: 1.5rem !important;
        /*Non sono 
        riuscita a vedere quanto dovrebbe essere da figma*/
        width: fit-content !important;
    }

    .title {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .text,
    .margin,
    .comingSoon {
        margin-bottom: 1.3rem;
    }

    .unlock_button {
        width: 100%;
    }
}

/*Mobile size*/
@media screen and (max-width: 768px) {


    div.customButton>div,
    div.customButton>div>button {
        width: 100%;
    }

    .stickyBanner {
        position: relative;
        left: auto;
        transform: none;
        bottom: auto;
        margin: 0 auto;
    }

}