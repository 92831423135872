

/* TAILWIND CONFIGURATION */

@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
  }
}

@layer utilities {
    .fontXXL {
      font-size: xx-large !important;
    }
  
    .fontXL {
      font-size: x-large !important;
    }
  
    .fontL {
      font-size: large !important;
    }
  
    .fontM {
      font-size: medium !important;
    }
  
    .fontS {
      font-size: small !important;
    }
  }
  

/* index.css */

.fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 400ms;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 400ms;
  }
  
  

/* SHARED STYLE */

@font-face {
    font-family: "Quattrocento-Regular";
    src: url("../fonts/Quattrocento/Quattrocento-Regular.ttf") format("truetype");  }
  
@font-face {
    font-family: "Quattrocento-Bold";
    src: url("../fonts/Quattrocento/Quattrocento-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter/Inter.ttf") format("truetype");
    font-weight: 100 900;
    font-style: normal;
}

:root {
--bb-red: #BB0A0B;
--bb-black: #0C0C0C;
--bb-white: #FDFDFD;
--bb-gray: #3A3A3A;
}

.red {
    color: var(--bb-red);
}

.black {
    color: var(--bb-black);
}

.gray {
    color: var(--bb-gray);
}

.white {
    color: var(--bb-white);
}

.regular {
    font-family: Quattrocento-Regular, sans-serif;
}

.bold {
    font-family: Quattrocento-Bold, sans-serif;
}

.bolder {
    font-family: Quattrocento-Bold, sans-serif;
    font-weight: 700;
}

/* Regular weight */
.inter-regular {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
  }
  
  /* Medium weight */
  .inter-medium {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
  }
  
  /* Semi-bold weight */
  .inter-semibold {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
  }
  
  /* Bold weight */
  .inter-bold {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
  }

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.no-underline {
    text-decoration: none;
}

.radius-teseo {
    border-radius: 40px;
}
.radius-box {
    border-radius: 16px;
}
.radius-card {
    border-radius: 24px;
}
.background-teseo-component {
    background-color: #1f1f1f;
}
.background-teseo-gradient {
    background: linear-gradient(90deg, #7b0000, #a90c0d, #6d0001);
}
.background-teseo {
    background-color: var(--bb-red);
}

.linkTeseo {
    text-decoration: underline;
    transition: color 0.3s; /* Add transition for smooth effect */
    color: var(--bb-white);
    cursor: pointer;
}

.linkTeseo:hover  {
    color: var(--bb-red);
}

.buttonTeseo {
    background-color: var(--bb-red);
    color: var(--bb-white);
    border: none;
    opacity: 1;
    transition: opacity 0.2s;
    border-radius: 40px;
    cursor: pointer;
    text-decoration: none;
}

.buttonTeseo:active, .buttonTeseo:focus, .buttonTeseo:disabled {
    background-color: var(--bb-red)!important;
    color: var(--bb-white);
    border: none;
    opacity: 1;
    transition: opacity 0.2s;
    border-radius: 15px;
}

.buttonTeseo:hover {
    opacity: 0.8;
    background-color: var(--bb-red);
    color: var(--bb-white);
    border: none;
}

.socialTeseo {
    color: var(--bb-white);
    transition: all 0.3s ease;
}

.socialTeseo:hover, .socialTeseo:focus {
    opacity: 0.7;
}

.box_plain_teseo {
    background-color:var(--bb-black);
    box-shadow: rgba(53, 53, 53, 0.24) 0px 3px 8px;
    border-radius: 20px;
}
.container_teseo {
    min-width: 90vw;
    min-height: 90vh;
}

.blurred-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    backdrop-filter: blur(5px); /* Apply blur effect */
    z-index: 1000;
  }

/*Progress bar*/
progress {
    width: 100%; /* Set the width to fill its container */
    height: 3px; /* Set the height */
    border-radius: 10px; 
    background: transparent; 
    border: none;
}
  
progress::-webkit-progress-value {
    border-radius: 10px;
    background: var(--bb-red);
    opacity: 0.6;
}

progress::-webkit-progress-bar {
    background-color: transparent;
  }


body {
    color: var(--bb-white);
    background-size: cover; /* Adjust as needed */
    background-position: center; /* Adjust as needed */
    background-repeat: no-repeat; /* Adjust as needed */
    width: 100%;
    height: 100%;
    background-color: #0C0C0C;
    height: 100%;
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
}

/* DA TOGLIERE ??? */
body.home-page {
    background-image: url('../images/home/backgroundHome.svg');
    /* Additional background styles for Home page */
  }
  
  /* Background style for Greco page */
body.greco-page {
    background-image: url('../images/greco/backgroundGreco.svg');
    /* Additional background styles for Greco page */
}

.mobile-only {
    display: none;
    position: absolute;
}

.desktop-only {
    display: block;
}

/* serve all'upload modal */
#file {
    display: none;
}

/* MOBILE DEVICES */
@media screen and (max-width: 990px) {
    body {
        color: var(--bb-white);
        background-image: url('../images/home/backgroundHomeMobile.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;
        overflow-x: hidden;
    }
    .mobile-only {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .desktop-only {
        display: none !important;
    }
}