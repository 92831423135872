
.resultBox {
  /* backdrop-filter: blur(10px); /* Apply blur effect 
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.5); */
  max-height: 80vh;
  overflow-y: auto;
  -webkit-user-select:none;
  -moz-user-select:none;
  user-select:none;
  background-color: #0C0C0C;
  border-radius: 4px;
}

/* For WebKit (Chrome, Safari, etc.) */
.resultBox::-webkit-scrollbar, .scrollBox::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  
.resultBox::-webkit-scrollbar-thumb, .scrollBox::-webkit-scrollbar-thumb {
    background-color: var(--bb-gray); /* Color of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners */
}

.resultBox::-webkit-scrollbar-track {
    color: transparent;
    border-radius: 0px;
}

.searchBox {
  background-color: #161616;
  border: none;
  width: 100%;
  position: sticky;
  top: -1px;
}

.inputBox {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
}

.scrollBox {
  max-height: 80vh;
  overflow-y: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  scrollbar-color: #A5A5A5 #161616;
  scrollbar-width: thin;
}

/* For WebKit-based browsers */
.scrollBox::-webkit-scrollbar {
  width: 10px; /* Adjust width */
  margin: 0 0.5rem;
}

.scrollBox::-webkit-scrollbar-track {
  background-color: #161616; /* Track color */
}

.scrollBox::-webkit-scrollbar-thumb {
  background-color: #A5A5A5; /* Thumb color */
  border-radius: 10px; /* Rounded corners */
}

.posButton {
  background-color: #1F1F1F;
  border-radius: 4px;
}


.fontButton {
  border-radius: 4px;
  background-color: #212121;
}

.fontButtonIcon {
  opacity: 0.6;
  padding: 0.3rem;
  transition: opacity 0.2s ease;
  font-size: 14px;
}

.fontButtonIcon:hover {
  opacity: 0.9;
}


.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it's on top */
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto; /* Allow scrolling if content is too long */
}

.closeModal {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Horizontal Scroll Container */
.horizontalScroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 0.5rem;
  padding: 0.5rem;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in IE/Edge */
  position: relative; /* To position pseudo-elements */
}

.horizontalScroll::-webkit-scrollbar {
  height: 8px; /* Adjust scrollbar height */
}

.horizontalScroll::-webkit-scrollbar-thumb {
  background: #161616;
  opacity: 0.3; /* Low opacity */
  border-radius: 4px;
  transition: opacity 0.3s ease-in-out; /* Smooth fade-in/out */
}

.horizontalScroll::-webkit-scrollbar-track {
  background: transparent; /* Fully transparent track */
}

.horizontalScroll.scrolling::-webkit-scrollbar-thumb {
  background: #161616;
  opacity: 0.5;/* Slightly more visible during scrolling */
}

/* Initially hide the scrollbar */
.horizontalScroll::-webkit-scrollbar-thumb {
  opacity: 0;
}

/* When user scrolls, show scrollbar */
.horizontalScroll.scrolling::-webkit-scrollbar-thumb {
  opacity: 1;
}
