.tbutton {
    background-color: rgba(187, 10, 11); 
    border-radius: 20px;
    border: none!important;
    cursor: pointer;
    text-decoration: none;
    font-size: 18px;
    opacity: 1;
    transition: opacity 1s ease, box-shadow 0.1s ease;
    box-shadow: rgba(187, 10, 11, 0.45) 0px 25px 20px -20px;  
}

.tbutton:hover {
    opacity: 0.9;
    box-shadow: rgba(187, 10, 11, 0.85) 0px 25px 25px -20px; 
}

.tbutton:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

.tbuttonBackground {
    height: 5.4em;
    width: 10em;
    background-image: url('../images/home/buttonTeseo.svg');
    background-size: cover; /* Adjust as needed */
    background-position: center; /* Adjust as needed */
    background-repeat: no-repeat; /* Adjust as needed */
}

.copyButton {
    bottom: 0;
    right: 0;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: var(--bb-gray);
}